import { PropsWithChildren, ReactNode, useEffect } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimateContent = ({ children }: PropsWithChildren) => {
  const controls = useAnimation();

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          transition: { duration: 0.5 },
          translateY: '0px',
        },
        hidden: {
          opacity: 0,
          transition: { duration: 0.5 },
          translateY: '50px',
        },
      }}
    >
      {children}
    </motion.div>
  );
};

export type SliceProps = BoxProps & {
  children?: ReactNode;
  animated?: boolean;
};

export const Slice = ({ animated = true, children, ...rest }: SliceProps) => {
  return animated ? (
    <AnimateContent>
      <Box {...rest}>{children}</Box>
    </AnimateContent>
  ) : (
    <Box {...rest}>{children}</Box>
  );
};
