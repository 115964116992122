import { css } from '@emotion/react';
import { Box } from '@mui/system';
import { Types } from '@scorenco/core';

import { forceThemeProps } from '../../themes';
import { SliceContainer } from '../SliceContainer';
import { Slice } from '../SliceContainer/Slice';

type SectionSliceProps = {
  slice: Types.ComponentSlicesSection & { children: any[] };
  mapper?: any;
};

const THEMES = {
  fond_bleu_clair: 'light' as const,
  fond_blanc: 'light' as const,
  fond_vert: 'green' as const,
  fond_bleu_fonce: 'blue' as const,
};

export const SectionSlice = ({ slice, mapper }: SectionSliceProps) => {
  const sectionTheme =
    slice.theme && THEMES[slice.theme as keyof typeof THEMES];
  const showShadows = slice.theme && ['fond_bleu_fonce'].includes(slice.theme);

  return (
    <Slice
      {...forceThemeProps(sectionTheme)}
      animated
      css={(theme) => css`
        position: relative;
        background-color: ${slice.theme === 'fond_blanc'
          ? theme.vars.palette.background.paper
          : theme.vars.palette.background.default};
        color: ${theme.vars.palette.text.primary};

        &::before {
          content: ' ';
          display: block;
          margin-bottom: ${theme.spacing(8)};
          height: 1px;
        }

        &::after {
          content: ' ';
          display: block;
          margin-top: ${theme.spacing(8)};
          height: 1px;
        }
      `}
    >
      {showShadows && (
        <Box
          css={css`
            position: absolute;
            background: linear-gradient(
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            top: 0;
            left: 0;
            right: 0;
            height: 1rem;
          `}
        />
      )}
      {showShadows && (
        <Box
          css={css`
            position: absolute;
            background: linear-gradient(
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.2) 100%
            );
            bottom: 0;
            left: 0;
            right: 0;
            height: 3rem;
          `}
        />
      )}
      <SliceContainer slices={slice.children} mapper={mapper} />
    </Slice>
  );
};
